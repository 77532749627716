import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'

export const buttonActions = cva(undefined, {
  variants: {
    alignedContent: {
      left: ['text-left'],
      center: ['text-center'],
      right: ['text-right']
    },
    alignedButtons: {
      left: ['items-start justify-start'],
      center: ['items-center justify-center'],
      right: ['items-end justify-end']
    },
    layout: {
      row: 'sm:flex-row flex-col',
      column: 'flex-col'
    }
  },
  defaultVariants: {
    alignedContent: 'left',
    layout: 'row'
  }
})

export type ButtonActionsProps = VariantProps<typeof buttonActions>
